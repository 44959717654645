<template>
  <main class="bg-light">
    <div>
      <keep-alive>
        <top-nav/>
      </keep-alive>
      <div>
        <router-view @listLoad="load"/>
      </div>
    </div>
    <b-container class="mt-4" fluid>
      <b-row>
        <b-col>
          <b-table-simple bordered small class="bg-white">
            <b-thead>
              <b-tr>
                <b-th colspan="9">
                  <div>
                    <filter-component
                      :filters="filters"
                      :filterData="filter"
                      :selectedId="selectedId"
                      v-on:search="search"
                    />
                  </div>
                </b-th>
              </b-tr>
              <b-tr>
                <b-th>
                  식별자
                </b-th>
                <b-th>
                  ID
                </b-th>
                <b-th>
                  이메일
                </b-th>
                <b-th>
                  닉네임
                </b-th>
                <b-th>
                  휴대폰
                </b-th>
                <b-th>
                  포인트(보너스)
                </b-th>
                <b-th>
                  등록일
                </b-th>
                <b-th>
                  추천인 상태
                </b-th>
                <b-th>
                  추천 받은 수
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="user in users"
                :key="user._id"
              >
                <b-td>
                  <router-link
                    :to="`/users/list/${user._id}/auth-history`"
                  >
                    {{ user._id }}
                  </router-link>
                </b-td>
                <b-td>
                  {{ user.userId }}
                </b-td>
                <b-td>
                  {{ user.userEmail }}
                </b-td>
                <b-td>
                  {{ user.nickName }}
                </b-td>
                <b-td>
                  {{ user.phone }}
                </b-td>
                <b-td>
                  {{ new Intl.NumberFormat().format(user.samPoint) }} ({{ new Intl.NumberFormat().format(user.bonusPoint) }})
                </b-td>
                <b-td>
                  {{ new Date(user.regDate).toLocaleString() }}
                </b-td>
                <b-td>
                  {{ user.recommenderTF ? '대상' : ''}}
                </b-td>
                <b-td>
                  {{user.recommenderCount}}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
        <hr/>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-center">
            <small class="font-weight-bold text-primary">
              {{total}}개가 검색되었습니다.
            </small>
            <b-pagination-nav
                :link-gen="linkGen"
                :number-of-pages="totalPage"
                use-router
                first-number
                last-number
                size="sm"
                align="right"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import filterComponent from '@/components/filter'
export default {
  name: "index",
  components: {
    filterComponent
  },
  data: () => ({
    page: 1,
    total: 0,
    totalPage: 1,
    filters: [
      {text: '선택', value: null},
      {text: 'ID', value: 'userId'},
      {text: '이메일', value: 'userEmail'},
    ],
    filter: {
      searchFilter: null,
      searchValue: null,
      perPage: 10,
    },
    users: [],
    selectedId: null
  }),
  created () {
    (async () => {
      const {_id} = this.$route.params
      if (_id) this.selectedId = _id
      const { page, searchFilter, searchValue, perPage } = this.$route.query
      if (page && !isNaN(+page)) this.page = +page
      if (searchFilter) this.filter.searchFilter = searchFilter
      if (searchValue) this.filter.searchValue = searchValue
      if (perPage && !isNaN(+perPage)) this.filter.perPage = +perPage
      await this.load()
    })()
  },
  methods: {
    async load () {
      try {
        const {data} = await this.axios({
          url: `/admin/users/list`,
          params: {
            page: this.page,
            ...this.filter
          }
        })
        const {result, page, total, users, totalPage} = data
        if (result) {
          this.page = page
          this.total = total
          this.users = users
          this.totalPage = totalPage
        }
      } catch (e) {
      }
    },
    linkGen (pageNum) {
      const filter = []
      filter.push(pageNum === 1 ? '' : `page=${pageNum}`)

      if (this.filter) {
        const filterData = Object.entries(this.filter).filter(([k, v]) => v).map(([k, v]) => {
          return `${k}=${v}`
        })
        if (filterData.length) filter.push(...filterData)
      }
      return `?${filter.join('&')}`
    }
  }
}
</script>

<style scoped>
main {
  min-height: 100vh;
}
th, td {
  font-size: 0.8rem;
  vertical-align: middle;
}
tr.active {
  color: red;
  font-weight: bold;
  background-color: #F5F5F5;
}
</style>
