<template>
  <div id="filter-form" class="ml-auto">
    <b-form v-on:submit.prevent="search">
      <div class="d-flex align-content-center align-items-center">
        <div class="p-1">
          <b-form-select
            size="sm"
            v-model="perPage"
            :options="perPageOptions"
            v-on:change="search"
          >
          </b-form-select>
        </div>
        <div class="p-1" v-if="statusFilters !== null">
          <b-form-select
              size="sm"
              v-model="searchStatus"
              :options="statusFilters">
          </b-form-select>
        </div>
        <div class="p-1">
          <b-form-select
            v-if="filters && filters.length"
            size="sm"
            v-model="searchFilter"
            :options="filters"
            v-on:change="filterChange(true)"
          />
        </div>
        <div class="p-1">
          <b-form-select
            v-if="searchFilterOptions.length"
            size="sm"
            :options="searchFilterOptions"
            v-model="searchValue"
          >

          </b-form-select>
          <b-form-input
            v-else
            size="sm"
            placeholder="검색값"
            v-model="searchValue"
          />
<!--          <b-form-select-->
<!--            size="sm"-->
<!--            v-model="searchFilter"-->
<!--            :options="filters"-->
<!--            v-on:change="filterChange"-->
<!--          />-->

        </div>
        <div class="p-1">
          <b-button
            type="submit"
            variant="danger"
            size="sm"
            squared
          >
            <font-awesome-icon
              icon="search"
            />
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  name: "filterComponent",
  props: {
    selectedId: String,
    filters: Array,
    filterData: {
      searchFilter: String,
      searchValue: String,
      perPage: String,
    },
    statusFilters: {
      type: Array,
      default: null
    },
    searchFilterSelect: Object
  },
  data: () => ({
    perPage: 10,
    searchFilter: null,
    searchValue: null,
    perPageOptions: [
      { text: '10개', value: 10 },
      { text: '20개', value: 20 },
      { text: '30개', value: 30 },
      { text: '40개', value: 40 },
      { text: '50개', value: 50 }
    ],
    // searchFilterSelect: null,
    searchFilterOptions: [],
    searchStatus: null
  }),
  created () {
    if (this.filterData) {
      const {searchFilter, searchValue, perPage, searchStatus, searchFilterSelect} = this.filterData
      if (perPage) this.perPage = perPage
      if (searchFilter) this.searchFilter = searchFilter
      if (searchValue) this.searchValue = searchValue
      if (searchStatus) this.searchStatus = searchStatus
      this.filterChange(false)
    }
  },
  methods: {
    search () {
      const params = {
        searchFilter: this.searchFilter,
        searchValue: this.searchValue,
        searchStatus: this.searchStatus,
        perPage: this.perPage
      }
      this.$emit('search', params)
    },
    filterChange (flag) {
      if (flag) this.searchValue = null
      if (this.searchFilterSelect) {
        if (this.searchFilterSelect[this.searchFilter]) {
          const entries = Object.entries(this.searchFilterSelect[this.searchFilter])
          this.searchFilterOptions = [
            { text: '선택', value: null },
            ...entries.map(([text, value]) => {
              return {
                text, value
              }
            })
          ]
          return
        }
      }
      this.searchFilterOptions = []
    }
  }
}
</script>

<style scoped>
div#filter-form {
  max-width: 500px;
}
</style>
